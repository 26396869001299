<!--
 * @Description: 路内停车管理系统 员工管理 巡检组管理 成员管理 chargeGroupMember
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-05-18 19:20:20
 * @LastEditors: zhoucheng
-->
<template>
  <div class='mainBody'>
    <el-row>
      <!--上半部查询-->
      <el-row class="topSelectTwoLine">
        <el-form :inline="true"
                 :model="searchForm">
          <el-row class="topFirstLine">
            <el-col :span="6">
              <el-form-item label-width="90px"
                            label="巡检员名称">
                <el-input v-model="searchForm.userId"
                          clearable
                          placeholder="请输入"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="topButton">
            <el-button type="primary"
                       @click="onSubmit">查 询</el-button>
            <el-button type="info"
                       @click="resetForm"
                       style="margin-left:17px;">重 置</el-button>
          </el-row>
        </el-form>
      </el-row>
      <el-row class="tableRow">
        <el-row class="tableTitle">
          <span>成员管理</span>
          <el-button class="tableTitleButton"
                     type="success"
                     @click="addHandle">新 增</el-button>
        </el-row>
        <el-row class="tableContent">
          <dt-table :tableColumnList="tableColumnList"
                    :data="tableList"
                    :map="listMap"
                    :tableConfig='tableConfig'
                    :paginationConfig='paginationConfig'
                    :config='tableConfig'
                    @getList="getList">
            <template slot="isFirst">
              <!-- <el-table-column type="selection"></el-table-column> -->
            </template>
            <template slot="operating">
              <el-table-column label="操作"
                               class-name='class-name'>
                <template slot-scope="scope">

                  <el-col :span="8">
                    <el-button type="text"
                               size="medium"
                               @click="deteleHandle(scope.row)">删除</el-button>
                  </el-col>
                </template>
              </el-table-column>
            </template>
          </dt-table>
        </el-row>
      </el-row>
      <!-- 查看用户弹出框   一个表格-->
      <el-dialog title="新增成员"
                 :visible.sync="dialogUserVisible"
                 append-to-body>
        <el-row style="margin-bottom:10px;">
          <el-form :inline="true"
                   :model="userSearchForm">
            <el-col :span="12">
              <el-form-item label-width="90px"
                            label="巡检员名称">
                <el-input placeholder="请输入"
                          v-model="userSearchForm.shiftName">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12"
                    style="display:flex">
              <el-button type="primary"
                         @click="userSearch">查 询</el-button>
            </el-col>
          </el-form>
        </el-row>
        <el-row>
          <dt-table :tableColumnList="tableChildColumnList"
                    :data="userRoleChildList"
                    :map="listMap"
                    :paginationConfig='paginationConfig'
                    :tableConfig="tableConfig"
                    @getList="getList"
                    :tableFun="tableFun">
            <template slot="isFirst">
              <el-table-column type="selection"></el-table-column>
            </template>
          </dt-table>
        </el-row>
        <el-row type="flex"
                justify="end">
          <el-col :span="12"
                  class="dt-button-box">
            <el-button type="primary"
                       @click="handleClose"
                       style="margin-left:17px;
                     border-radius:4px;
                     height:36px;">确 定</el-button>
            <el-button @click="dialogVisible = false"
                       type="info">取 消</el-button>
          </el-col>
        </el-row>
      </el-dialog>
    </el-row>
    <el-row></el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      tableFun: { 'selection-change': this.handleChange, },
      tableConfig: { border: true },
      listMap: {
        pageNum: 1,
        pageSize: 10,
      },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      //列表表头
      tableColumnList: [
        {
          prop: 'userCode',
          label: '编号',
        },
        {
          prop: 'userName',
          label: '名称',
        },
      ],
      //列表
      tableList: {
        list: [],
      },
      //搜索表单
      searchForm: {
        userId: '',
      },
      //子列表表头
      tableChildColumnList: [
        {
          prop: 'userCode',
          label: '编号',
        }, {
          prop: 'userName',
          label: '名称',
        },
      ],
      //弹框列表表头
      userRoleChildList: {
        list: []
      },
      //弹框搜索表单
      userSearchForm: {
        shiftName: '',
      },
      dialogUserVisible: false, //子表弹窗显示
      dialogTitle: '',//弹窗标题
      massDeleteList: [],//多选数组
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.initialization()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    initialization () {
      this.tableList.list = this.$route.query.rowList
    },
    //分页
    getList () {
      console.warn('获取内容')
    },
    //查询
    onSubmit () {
      console.log(this.searchForm)
    },
    //重置
    resetForm () {
      this.searchForm = {}
    },
    //新增
    addHandle () {
      this.dialogUserVisible = true
    },
    //表格多选
    handleChange (val) {
      console.log('选择了', val)
      this.massDeleteList = val
    },
    //
    handleClose () {
      if (this.massDeleteList.length == 0) {
        this.$message('至少选择一个角色');
      } else {
        this.dialogUserVisible = false
        console.log(this.massDeleteList)
      }
    },
    //弹框查询
    userSearch () {
      console.log(this.userSearchForm)
    },
    deteleHandle (row) {
      console.log(row)
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 135px;

// 最外层div
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
.dt-form-width {
  width: 230px;
}
</style>